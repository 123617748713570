/**
 * Contrôleur général de l'application "Tasks Visipilote"
 * @type {Object}
 */
vtCtrl.controller('vtCtrl', [
	'$window', '$location', '$route', '$routeParams', '$timeout', '$rootScope', '$scope', '$q', '$sce', '$http', 'vcRecaptchaService', 'toaster', 'vtApi',
function($window, $location, $route, $routeParams, $timeout, $rootScope, $scope, $q, $sce, $http, recaptcha, toaster, vtApi) {
	// ============== \\
	// Initialisation \\
	// ============== \\
	$scope.initVars = function() {
		//var ctm = this; // Portée du "scope" Angular
		$scope.users = []; // Liste des utilisateurs assignables à une tâche
		$scope.user = {}; // Données des utilisateurs
		$scope.projects = []; // Liste des projets
		$scope.issues = []; // Liste des demandes (non-tâche) variables par projet
		$scope.tasks = []; // Liste des tâches variables par projet et demande
		$scope.token = ''; // Suppression du token
		$scope.project = {}; // Projet sélectionné
		$scope.issue = {}; // Demande sélectionné
		$scope.trackers = []; // Liste des trackers
		$scope.statuses = []; // Liste des statuts
		$scope.taskvalue = ''; // Titre de la tâche
		$scope.userAssign = {}; // Identifiant de l'utilisateur à assigner
		$scope.offsetsize = 16; // Décalage en pixel
		$scope.isHome = false; // S'agit-il de la page d'accueil ?
	};
	$scope.initVars();

	// Configuration de la scrollbars dynamique
	$scope.scrollbarsConfig = {
		autoHideScrollbar: true,
		theme: 'minimal',
		advanced: {
			updateOnContentResize: true
		}
	};

	// Récupération du token utilisateur si existant
	if (localStorage.getItem('vt-token')) {
		$scope.token = localStorage.getItem('vt-token');
		vtApi.setToken($scope.token);
	}

	// Si le token existe, on récupère les informations de l'utilisateur
	if ($scope.token) {
		// Utilisation de l'authentification par clé API
		$http.defaults.headers.common['X-Redmine-API-Key'] = $scope.token;

		// Récupération des données de l'utilisateur connecté par la clé API
		vtApi.get('users/current.json').then(function(res) {
			$scope.user = res.data.user;
			$scope.user.isLogged = true;
			$scope.waitBtn=false;
			$scope.init();
		}, function(res) {
			$scope.user.isLogged = false;
			toaster.pop('error', 'Erreur', 'Votre clé API n’est plus valide');
			$scope.waitBtn=false;
		});
	} else {
		$location.path('/');
	}

	// Affichage du menu mobile
	$scope.toggleMenu = function() {
		$('#projects-menu').toggleClass('show-projects-menu');
	};

	// Changement de projet
	$scope.changeProject = function(pid) {
		$location.path('/project/'+pid);
		$('#projects-menu').removeClass('show-projects-menu');
		$scope.loadProject(pid).then(function(res) {
			$scope.project = res.data.project;
			$scope.issue = {};
			$scope.showissues(pid);
		});
	};
	// Chargement des données d'un projet
	$scope.loadProject = function(pid) {
		console.log(pid);
		return vtApi.get('projects/'+pid+'.json');
	};

	// Affichage de la liste des tâches d'un projet
	$scope.showissues = function(pid) {
		// Ajout de l'utilisateur assigné si renseigné
		var assigned_to = 0;
		if (!$scope.userAssign.id) {
			assigned_to = $scope.user.id;
		} else {
			assigned_to = $scope.userAssign.id;
		}

		// OLD : issues.json?assigned_to_id=me&project_id='+pid
		return $q(function(resolve, reject) {
			vtApi.get('issues.json?project_id='+pid+'&status_id=*').then(function(res) {
				console.log('issues.json?project_id='+pid+'&status_id=*', res);
				$scope.issues = [];
				$scope.tasks = [];
				for (var i in res.data.issues) {
					if (res.data.issues[i].tracker.id != $scope.vtConfig.TASK_TRACKER_ID) {
						if (res.data.issues[i].status.id != 5)
							$scope.issues.push(res.data.issues[i]);
					} else {
						if (res.data.issues[i].assigned_to.id == assigned_to || res.data.issues[i].author.id == assigned_to) {
							$scope.tasks.push(res.data.issues[i]);
						}
					}
				}
				//$scope.issues = res.data.issues;
				resolve();
			});
		});
	};

	$scope.showLastTasks = function() {
		// Ajout de l'utilisateur assigné si renseigné
		var assigned_to = 0;
		if (!$scope.userAssign.id) {
			assigned_to = $scope.user.id;
		} else {
			assigned_to = $scope.userAssign.id;
		}

		vtApi.get('issues.json?tracker_id='+$scope.vtConfig.TASK_TRACKER_ID+'&status_id=*&assigned_to_id='+assigned_to).then(function(res) {
			console.log('issues.json?tracker_id='+$scope.vtConfig.TASK_TRACKER_ID+'&status_id=*&assigned_to_id='+assigned_to, res);
			$scope.tasks = [];
			for (var i in res.data.issues) {
				$scope.tasks.push(res.data.issues[i]);
			}
		});
	};

	// Chargement de la demande et des tâches associées à cette demande
	$scope.loadIssue = function(issue, noChangeLocation) {
		if (!noChangeLocation)
			$location.path('/issue/'+issue.id);

		// Ajout de l'utilisateur assigné si renseigné
		var assigned_to = 0;
		if (!$scope.userAssign.id) {
			assigned_to = $scope.user.id;
		} else {
			assigned_to = $scope.userAssign.id;
		}

		return $q(function(resolve, reject) {
			vtApi.get('issues/'+issue.id+'.json?include=children').then(function(res) {
				console.log('issues/'+issue.id+'.json?include=children', res);
				$scope.issue = res.data.issue || [];
				$scope.tasks = [];
				var tasks_ids = [];
				for (var i in res.data.issue.children) {
					tasks_ids.push(res.data.issue.children[i].id);
				}
				if (tasks_ids.length >= 1) {
					vtApi.get('issues.json?issue_id='+tasks_ids.join(',')+'&status_id=*').then(function(res) {
						console.log('issues.json?issue_id='+tasks_ids.join(',')+'&status_id=*', res);
						var tasks = res.data.issues || [];

						$scope.tasks = [];
						for (var i in tasks) {
							if (tasks[i].assigned_to.id == assigned_to || tasks[i].author.id == assigned_to) {
								$scope.tasks.push(tasks[i]);
							}
						}
					});
				}
				resolve(res);
			}, function() {
				reject();
			});
		});
	};

	// Ajout d'une tâche au projet en cours
	$scope.addTask = function(subject) {
		$('input[name=taskbar]').prop('disabled', true);
		console.log($scope.project.id, subject, $scope.user.id);
		if ($scope.project.id) {
			// Ajout de l'utilisateur assigné si renseigné
			var assigned_to = 0;
			if (!$scope.userAssign.id) {
				assigned_to = $scope.user.id;
			} else {
				assigned_to = $scope.userAssign.id;
			}

			// Demande parente à la tâche si renseigné
			var parent_issue = 0;
			if ($scope.issue.id) {
				parent_issue = $scope.issue.id;
			}

			// Création de la tâche
			vtApi.post('issues.json', {
				issue: {
					project_id: $scope.project.id,
					tracker_id: 10,
					status_id: 1,
					priority_id: 2,
					parent_issue_id: parent_issue,
					subject: subject,
					description: subject,
					assigned_to_id: assigned_to
				}
			}).then(function(res) {
				$scope.tasks.push(res.issue);
				$('input[name=taskbar]').val('');
				$('input[name=taskbar]').prop('disabled', false);
			}, function(res) {
				console.log(res);
				toaster.pop('error', 'Erreur', 'Votre tâche n’a pas pû être créé');
				$('input[name=taskbar]').prop('disabled', false);
			});
		} else {
			toaster.pop('error', 'Erreur', 'Vous devez choisir un projet');
		}
	};

	// Suppression d'une tâche
	$scope.removeTask = function(taskid, i) {
		vtApi.delete('issues/'+taskid+'.json').then(function(res) {
			console.log(res);
			$scope.tasks.splice(i, 1);
		}, function() {
			toaster.pop('error', 'Erreur', 'La tâche n’a as été supprimée');
		});
	};

	// Mise à jour du statut d'une tâche
	$scope.updateTaskStatus = function(taskid, status_id, index) {
		vtApi.put('issues/'+taskid+'.json', {
			'issue': {
				status_id: status_id
			}
		}).then(function(res) {
			console.log('issues/'+taskid+'.json', res, $scope.tasks[index].status.id);
			$scope.tasks[index].status.id = status_id;
		}, function(res) {
			// Fix : vtApi retourne "error" malgré l'utilisation de "resolve" ???
			console.log('issues/'+taskid+'.json', res);
			if (res.status == 200) {
				$scope.tasks[index].status.id = status_id;
			}
		});
	};

	// Action lors de l'appuie sur une touche
	$scope.atFilter = {'name':false};
	$scope.getKeyAction = function(evt, taskvalue) {
		console.log('enter', evt, taskvalue);
		// Entrer
		if (evt.keyCode == 13)
			$scope.addTask(taskvalue);

		// Arobase
		console.log($scope.atFilter);
		if (typeof $scope.atFilter === 'object' && typeof $scope.atFilter.name === 'string') {
			$scope.atFilter.name += String(evt.key);
			console.log($scope.atFilter);
		}
		if (evt.key == '@' && !$scope.userAssign.id) {
			$scope.atFilter = {'name':String('')};
			$scope.getUsersList();
		}
		if (!/@/.test(taskvalue)) {
			$scope.atFilter = {'name':false};
			$('.userslist').hide();
		}
	};

	// Utilisateur assigné
	$scope.addAssign = function(user) {
		$scope.atFilter = {'name':false};
		$scope.userAssign = user;
		$('.userslist').hide();
	};

	// Suppression de l'utilisateur assigné
	$scope.removeAssign = function() {
		$scope.userAssign = {};
	};

	// Affichage de la liste des utilisateurs assignables
	$scope.nfilter = '';
	$scope.getUsersList = function() {
		$scope.users = [];
		/* Admin requis !
		vtApi.get('users.json?group_id=47').then(function(res) {
			$scope.users = res.data.users;
			$('.userslist').show();
		}, function() {
			toaster.pop('error', 'Erreur', 'Impossible de charger la liste des utilisateurs');
		});
		*/
		console.log('LOAD memberships');
		vtApi.get('projects/'+$scope.project.id+'/memberships.json').then(function(res) {
			console.log('projects/'+$scope.project.id+'/memberships.json', res);
			$scope.users = [];
			for (var i in res.data.memberships) {
				if (res.data.memberships[i].user) {
					$scope.users.push(res.data.memberships[i].user);
				}
			}
			$('.userslist').show();
		}, function() {
			toaster.pop('error', 'Erreur', 'Impossible de charger la liste des utilisateurs');
		});
	};

	$scope.setWidgetId = function(widgetName, widgetId) {
		$scope.recaptchaIds[widgetName] = widgetId;
	};

	/**
	 * Enregistrement d'une nouvelle tresse à la configuration
	 */
	/*$scope.save = function() {
		var tresseid = $scope.data.id;
		$scope.makeReference();
		if (typeof tresseid !== 'undefined') {
			delete $scope.data.id;
			$scope.tresses[tresseid] = angular.copy($scope.data);
		} else {
			$scope.tresses.push(angular.copy($scope.data));
		}
		$scope.data.saved = true;

		// Si l'utilisateur est connecté, on sauvegarde en ligne
		if ($scope.user.isLogged) {
			ctmApi.post('stepsave', {
				user: angular.copy($scope.user),
				user_data: angular.copy($scope.data),
				user_tresses: angular.copy($scope.tresses),
				user_step: angular.copy($scope.step)
			}).then(function(data) {
				toaster.pop('success', gettext('Tresse enregistré'), gettext('Votre tresse a correctement été enregistrée sur votre compte'));
			}, function(res) {
				toaster.pop('error', gettext('Tresse non synchronisé'), res.data.message);
			});
		}
	};

	// Initialisation des actions de la fenêtre modale
	$scope.validateModal = function() {};
	$scope.cancelModal = function() {};
	// Edition d'une tresse enregsitré
	$scope.edit = function(i) {
		var modal = new Foundation.Reveal($('#validModal'));
		$scope.validateModal = function() {
			$scope.data = angular.copy($scope.tresses[i]);
			$scope.data.id = i;
			$scope.goStep(1);
			modal.close();
		};
		$scope.cancelModal = function() {
			modal.close();
		};
		$('#validModal p').html(
			gettextCatalog.getString(
				'Vous allez charger la tresse N°{{L_TRESSE_NB}}<br />'+
				'Si vous continuez, la tresse en cours de configuration sera perdue !<br />'+
				'Voulez-vous continuer ?',
				{
					L_TRESSE_NB: (i+1)
				}
			)
		);
		modal.open();
	};
	// Suppression d'une tresse enregsitré
	$scope.remove = function(i) {
		var modal = new Foundation.Reveal($('#validModal'));
		$scope.validateModal = function() {
			// On supprime la tresse
			$scope.tresses.splice(i, 1);

			// Si la tresse supprimé était en cours d'édition ou s'il n'y a plus de tresse alors qu'on
			// est aux étapes après la sauvegarde d'une tresse
			// Réinitialisation de la tresse en cours et retour à l'étape 1
			if ($scope.data.id == i || $scope.tresses.length <= 0 && $scope.data.saved) {
				$scope.data = angular.copy($scope.idata);
				$scope.goStep(1).then(function() {
					toaster.pop('success', gettext('Tresse supprimée'), gettext('La tresse a été supprimée avec succès'));
				}, function(data) {
					if (data) {
						toaster.pop('error', gettext('Tresse non supprimée'), res.data.message);
					} else {
						toaster.pop('error', gettext('Tresse non supprimée'), gettext('La tresse n’a pas été supprimée'));
					}
				});
			} else {
				toaster.pop('success', gettext('Tresse supprimée'), gettext('La tresse a été supprimée avec succès'));
			}
			modal.close();
		};
		$scope.cancelModal = function() {
			modal.close();
		};
		$('#validModal p').text(
			gettextCatalog.getString(
				'Voulez-vous vraiment supprimer la tresse N°{{L_TRESSE_NB}}',
				{
					L_TRESSE_NB: (i+1)
				}
			)
		);
		modal.open();
	};*/

	/**
	 * Connexion de l'utilisateur
	 */
	$scope.login = function() {
		// Désactivation du bouton de connexion
		$scope.waitBtn=true;

		// Défini du header Auth Basic pour la connexion au compte
		var auth = window.btoa($scope.user.username+':'+$scope.user.password);
		$http.defaults.headers.common['Authorization'] = 'Basic ' + auth;

		// Récupération des informations de l'utilisateur connecté par Auth Basic
		vtApi.get('users/current.json').then(function(res) {
			toaster.pop('success', 'Succès', 'Vous êtes connecté');

			// Récupération des informations de l'utilisateur
			$scope.user = res.data.user;

			// Ajout de la clé API (vt-token) au stockage local, au scope et au service API
			localStorage.setItem('vt-token', $scope.user.api_key);
			$scope.token = $scope.user.api_key;
			vtApi.setToken($scope.token);

			// Remplacement de la méthode d'authentification Basic par la clé API
			delete $http.defaults.headers.common['Authorization'];
			$http.defaults.headers.common['X-Redmine-API-Key'] = $scope.token;

			// Indique que l'utilisateur est connecté et réactivation du bouton de connexion
			$scope.user.isLogged = true;
			$scope.waitBtn=false;

			// Initialisation de l'environnement
			$scope.init();
		}, function(res) {
			$scope.user.isLogged = false;
			toaster.pop('error', 'Erreur', 'Votre identifiant ou votre mot de passe est erroné');
			$scope.waitBtn=false;
		});
	};

	/**
	 * Déconnexion de l'utilisateur
	 */
	$scope.logout = function() {
		localStorage.removeItem('vt-token');
		delete $http.defaults.headers.common['Authorization'];
		delete $http.defaults.headers.common['X-Redmine-API-Key'];
		vtApi.setToken('');
		$scope.initVars();
		//window.location.href=window.location.href.split('#')[0];
	};

	// Initialisation de l'application
	$scope.init = function() {
		// Affichage de la liste des projets
		vtApi.get('projects.json?limit=9999').then(function(res) {
			$scope.projects = res.data.projects;
			console.log($scope.projects);

			// Mise à jour générique des données
			$scope.update();
		});

		// Récupération de la liste des trackers
		vtApi.get('trackers.json').then(function(res) {
			$scope.trackers = res.data.trackers;
		});

		// Récupération de la liste des statuts
		vtApi.get('issue_statuses.json').then(function(res) {
			$scope.statuses = res.data.issue_statuses;
		});
	};

	$scope.update = function() {
		// Chargement des données en fonction de la route
		if ($routeParams.issue_id) {
			$scope.isHome = false;
			$scope.loadIssue({id: $routeParams.issue_id}, true).then(function(ires) {
				$scope.loadProject(ires.data.issue.project.id, true).then(function(pres) {
					$scope.project = pres.data.project;
					$scope.showissues(pres.data.project.id).then(function() {
						$scope.loadIssue({id: $routeParams.issue_id}, true);
					});
				});
			});
		} else if ($routeParams.project_id) {
			$scope.isHome = false;
			$scope.loadProject($routeParams.project_id).then(function(pres) {
				$scope.project = pres.data.project;
				$scope.issue = {};
				$scope.showissues(pres.data.project.id);
			});
		} else {
			$scope.isHome = true;
			$scope.showLastTasks();
		}
	};

	$scope.displayTaskEditMode = function(index) {
		$scope.tasks[index].editMode = true;
		console.log($('.taskslist ul li').eq(index).find('input'), index);
		$timeout(function() {
			$('.taskslist ul li').eq(index).find('input').focus();
		});
	};
	$scope.updateTaskSubject = function(evt, task) {
		// Lorsque la touche "Entrer" est appuyée
		if (evt.keyCode == 13) {
			vtApi.put('/issues/'+task.id+'.json', {
				issue: {
					subject: task.vt_newsubject
				}
			}).then(function() {
				task.subject = task.vt_newsubject;
				task.editMode = false;
			}, function() {
				toaster.pop('error', 'Erreur', 'La tâche n’a pas pu être mise à jour');
				task.editMode = false;
			});




			/*// Création de la tâche
			vtApi.post('issues.json', {
				issue: {
					project_id: $scope.project.id,
					tracker_id: 10,
					status_id: 1,
					priority_id: 2,
					parent_issue_id: parent_issue,
					subject: subject,
					description: subject,
					assigned_to_id: assigned_to
				}
			}).then(function(res) {
				$scope.tasks.push(res.issue);
				$('input[name=taskbar]').val('');
				$('input[name=taskbar]').prop('disabled', false);
			}, function(res) {
				console.log(res);
				toaster.pop('error', 'Erreur', 'Votre tâche n’a pas pû être créé');
				$('input[name=taskbar]').prop('disabled', false);
			});*/
		}

		// Lorsque la touche "Echap" est appuyée
		if (evt.keyCode == 27) {
			task.editMode = false;
		}
	};

	// Actualisation des données lorsqu'un utilisateur utilise le bouton précédent/suivant
	$rootScope.$on('$locationChangeSuccess', function() {
		$rootScope.actualLocation = $location.path();
	});
	$rootScope.$watch(function() { return $location.path() }, function(newVal, oldVal) {
		if ($rootScope.actualLocation === newVal) {
			$scope.update();
		}
	});

	// Calcul le décalage d'un projet dans le menu des projets
	$scope.getProjectMenuOffset = function(p, projects, size) {
		if (p.parent) {
			size += $scope.offsetsize;
			var parent_project = $.grep(projects, function(e) { return e.id == p.parent.id })[0];
			size = $scope.getProjectMenuOffset(parent_project, projects, size);
		}
		return size;
	};

	$(document).mouseup(function(e) {
		var container = $('.userslist');
		var secondcontainer = $('#taskvalue');
		if (!container.is(e.target) && container.has(e.target).length === 0 && !secondcontainer.is(e.target) && secondcontainer.has(e.target).length === 0) {
			container.hide();
		}
	});
}]);
