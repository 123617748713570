/**
 * Initialisation du système d'ajustement du menu des projets
 * @return object Directive vtProjectsMenu
 */
vtDirs.directive('vtProjectsMenu', [function() {
	return {
		restrict: 'A',
		link: function(scope, element, attrs) {
			scope.$evalAsync(function() {
				// Ajustement du margin-top de vp-projectslist
				var vtpm_headerHeight = $('#header').outerHeight() || 0;
				var vtpm_marginTop = setVtpmMarginTop();
				$(document).scroll(function() {
					vtpm_marginTop = setVtpmMarginTop();
				});

				// Mise à jour du margin-top de vp-projectslist en fonction du scroll
				function setVtpmMarginTop() {
					var offset_scroll = $(window).scrollTop() || 0;
					var new_marginTop = 0;
					if (vtpm_headerHeight > offset_scroll) {
						new_marginTop = vtpm_headerHeight-offset_scroll
					} else {
						new_marginTop = 0;
					}
					element.css({'margin-top':new_marginTop+'px'});
					return new_marginTop;
				}

				// Ajustement de la hauteur de vp-projectslist
				console.log(setVtpmHeight());
				$(window).resize(function() {
					console.log(setVtpmHeight());
				});
				$(document).scroll(function() {
					setVtpmHeight();
				});
				function setVtpmHeight() {
					var winHeight = $(window).outerHeight() || 0;
					var vtpm_height = winHeight-vtpm_marginTop
					element.css({'height':vtpm_height+'px'});
					return vtpm_height;
				}
			});
		}
	};
}]);
