/**
 * Exécution des requêtes vers l'API Redmine
 */
vtServ.factory('vtApi', ['$rootScope', '$http', '$q', '$location', 'toaster', function($rootScope, $http, $q, $location, toaster) {
	var vtApi = {};
	vtApi.token = '';

	vtApi.setToken = function(token) {
		vtApi.token = token;
	};

	/**
	 * Récupération des données (Méthode GET)
	 * @param  {string} q La requête à envoyer
	 * @return {$q} Une promise
	 */
	vtApi.get = function(path) {
		return $q(function(resolve, reject) {
			$http.get(vtConfig.API_PATH + path, {
				responseType: 'json'
			}).then(function(res) {
				resolve(res);
			}, function(res) {
				reject(res);
			});
		});
	};

	/**
	 * Ajout d'une ressource (Méthode POST)
	 * @param  {string} q      La requête
	 * @return {object} object Les données
	 */
	vtApi.post = function(path, data) {
		return $q(function(resolve, reject) {
			// Suppression des variables AngularJS "$$"
			var cleanedObj = angular.copy(data);

			$.ajax({
				type: 'POST',
				url: vtConfig.API_PATH + path,
				data: cleanedObj,
				beforeSend: function(xhr) {
					xhr.setRequestHeader('X-Redmine-API-Key', vtApi.token);
				}
			}).done(function(data) {
				console.log('success', data);
				resolve(data);
			}).fail(function(res) {
				if (res.status == 200) {
					console.log('success empty', res);
					resolve(res.status);
				} else {
					console.log('fail', res);
					reject(res);
				}
			});
		});
	};

	/**
	 * Modification d'une ressource (méthode PUT)
	 * @param  {string} q      La requête
	 * @param  {object} object Les données
	 * @param  {files}  files  Les fichiers à envoyer
	 * @return {object}        La promise
	 */
	vtApi.put = function(path, data) {
		return $q(function(resolve, reject) {
			// Suppression des variables AngularJS "$$"
			var cleanedObj = angular.copy(data);

			$.ajax({
				type: 'PUT',
				url: vtConfig.API_PATH + path,
				data: cleanedObj,
				beforeSend: function(xhr) {
					xhr.setRequestHeader('X-Redmine-API-Key', vtApi.token);
				}
			}).done(function(data) {
				console.log('success', data);
				resolve(data);
			}).fail(function(res) {
				if (res.status == 200) {
					console.log('success empty', res);
					resolve(res.status);
				} else {
					console.log('fail', res);
					reject(res);
				}
			});
		});
	};

	/**
	 * Suppression d'une ressource (méthode DELETE)
	 * @param  {string} q La requête
	 * @return {object}   Une promise
	 */
	vtApi.delete = function(path) {
		// La suppression est assurée par la méthode GET
		return $q(function(resolve, reject) {
			$http.delete(vtConfig.API_PATH + path, {
				responseType: 'json'
			}).then(function(res) {
				console.log(res);
				resolve(res);
			}, function(res) {
				reject(res);
			});
		});
	};

	return vtApi;
}]);
