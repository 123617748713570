/**
 * Lancement de l'application "Tasks Visipilote"
 */
var vtApp = angular.module('vtApp', [
	'ngRoute',
	'ngSanitize',
	'vcRecaptcha',
	'toaster',
	'mm.foundation',
	'vtServices',
	'vtDirectives',
	'vtControllers',
	'ngScrollbars'
]);

// Action au chargement de l'application
vtApp.config([
	'$httpProvider', '$routeProvider', '$locationProvider', 'ScrollBarsProvider',
	function($httpProvider, $routeProvider, $locationProvider, ScrollBarsProvider) {
		// Modification des headers de requêtes par défaut
		$httpProvider.defaults.headers.get = {};
		$httpProvider.defaults.headers.post = {};
		$httpProvider.defaults.headers.put = {};
		$httpProvider.defaults.headers.patch = {};
		$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
		$httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

		// Chargement des routes
		$routeProvider
			.when('/',{})
			.when('/project/:project_id',{})
			.when('/issue/:issue_id',{})
			.when('/task/:task_id',{});

		// Suppression du # dans les url's
		$locationProvider.html5Mode(true);

		ScrollBarsProvider.defaults = {
			scrollButtons: {
				scrollAmount: 'auto', // scroll amount when button pressed
				enable: false // enable scrolling buttons by default
			},
			scrollInertia: 400, // adjust however you want
			axis: 'yx', // enable 2 axis scrollbars by default,
			theme: 'minimal',
			autoHideScrollbar: true
		};
	}
]).run(['$rootScope', '$location', function($rootScope, $location) {
	// Association de la configuration générale à l'application
	$rootScope.vtConfig = vtConfig;

	// Récupération de la langue dans l'URL
	var urlsplitted = window.location.pathname.split(vtConfig.SUB_PATH);
}]);

// Initialisation des services
var vtServ = angular.module('vtServices', [
	'toaster'
]).config(['$httpProvider', function($httpProvider) {
	//Reset headers to avoid OPTIONS request (aka preflight)
	$httpProvider.defaults.headers.get = {};
	$httpProvider.defaults.headers.post = {};
	$httpProvider.defaults.headers.put = {};
	$httpProvider.defaults.headers.patch = {};
	$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
	$httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
}]);

// Initialisation des directives
var vtDirs = angular.module('vtDirectives', []);

// Initialisation des contrôleurs
var vtCtrl = angular.module('vtControllers', [
	'ngSanitize',
	'vcRecaptcha',
	'toaster',
	'mm.foundation',
	'ngScrollbars'
]);
